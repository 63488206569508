/* eslint-disable no-unused-vars */
import React from "react";
import "./gallery.css";
import { useTranslation } from "react-i18next";
import { IoMdArrowDropright } from "react-icons/io"
import { IoMdArrowDropleft } from "react-icons/io"

function Gallery() {
    const [slideLeft, setSlideLeft] = React.useState(0);
    const [isScrolling, setIsScrolling] = React.useState(false);
    const sliderWidth = 1900;
    const { t } = useTranslation();

    const moveRight = () => {
        const el = document.getElementById(`images`);
        setSlideLeft((el.scrollLeft += 300));
    };

    const moveLeft = () => {
        const el = document.getElementById(`images`);
        setSlideLeft((el.scrollLeft -= 300));
    };

    if (isScrolling) {
        setTimeout(() => setIsScrolling(false), 3000);
    }
    return (
      <div id="gallery">
        <div className="container gallery-container">
            <div className="gallery-title-container">
                <h2>{t('customers_and_project')}</h2>
            </div>
            <div className="gallery-section">
                <button id="arrow-back" onClick={moveLeft}>
                    <IoMdArrowDropleft size={"70px"} className="buttonIcon" />
                </button>
                <div className="images-section" id="images" onScroll={() => {setIsScrolling(true);}}>
                    <div className="image-container" id="img1">
                        <div className="image-info-gallery">
                            <h2>Fuyao Glass</h2>
                            <p>Moraine, Ohio</p>
                        </div>
                    </div>
                    <div className="image-container" id="img2">
                        <div className="image-info-gallery">
                            <h2>Jinko Solar</h2>
                            <p>Jacksonville, Florida</p>
                        </div>
                    </div>
                    <div className="image-container" id="img3">
                        <div className="image-info-gallery">
                            <h2>Jushi Fiberglass</h2>
                            <p>Columbia, South Carolina</p>
                        </div>
                    </div>
                    <div className="image-container" id="img4">
                        <div className="image-info-gallery">
                            <h2>Hailiang</h2>
                            <p>Sealy, Texas</p>
                        </div>
                    </div>
                    <div className="image-container" id="img5">
                        <div className="image-info-gallery">
                            <h2>Minth International</h2>
                            <p>Tennessee</p>
                        </div>
                    </div>
                    <div className="image-container" id="img6">
                        <div className="image-info-gallery">
                            <h2>Yinlun TDI, LLC</h2>
                            <p>Morton, Illinois</p>
                        </div>
                    </div>
                </div>
                <button id="arrow-forward" onClick={moveRight}>
                    <IoMdArrowDropright size={"70px"} className="buttonIcon" />
                </button>
            </div>
        </div>
      </div>
    );
  }
  export default Gallery;
  