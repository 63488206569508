import "./contact.css";
import img from "../assets/bsw.png";
import React, {Component, useRef} from "react";
import { useTranslation } from "react-i18next";
import $ from "jquery";
// import Snackbar from "../snackbar/snackbar";


// const snackbarRef = useRef(null);

class Contact extends Component {

  state = {
    name: '',
    email: '',
    message: '',
    zip: '',
    company: '',
    success: '',
    error: ''
  };

  // handle the value
  handleCHange = input => e => {
    this.setState({[input]: e.target.value});
  }

  // when submit btn is clicked
  submitForm = (e) => {
    const {name, email, message, zip, company} = this.state;

    if (name === '' || email === '' || message === '' || zip === '') {
      e.preventDefault();
      this.setState ({
        error:  <MessageForm message="fill_fields" />,
        success: ''
      });
      return false;
    } else {
      $.ajax({
        url: "https://chiahan.com/extra-services/sendMail.php",
        type: 'POST',
        data: {
          'send': "send",
          'name': name,
          'email': email,
          'message': message,
          'zip': zip,
          'company': company
        },
        cache: false,
        success: function(data) {
          console.log('success', data);
        }.bind(this),
        // Fail..
        error: function(xhr, status, err) {
          console.log('fail');
        }.bind(this)
      });
      // snackbarRef.current.show();
      e.preventDefault();

      this.setState ({
        name: '',
        email: '',
        message: '',
        zip: '',
        company: '',
        success: <MessageForm message="sent" />,
        error: ''
      });
    }
  }

  render(){
    const {name, email, message, company, zip, error, success} = this.state;
    return (
      <div id="contact">
        {/* <Snackbar
          ref={snackbarRef}
          message="Task Completed Successfully!"
          type="success"
        /> */}
        <div className="container contact-container">
            <TitleText />
            <div id="contact-section">
                <div id="contact-info">
                    <img src={img} alt='Best Water' />
                    <p><span>BW Industrial Construction</span></p>
                    <p><br></br>2825 Wilcrest Dr., Suite 421</p>
                    <p>Houston, TX 77042</p>
                    <p>+1 (281) 888-5490</p>
                    <p>info@bsw-epc.com</p>
                </div>
                <form method="POST" onSubmit={this.submitForm} id="contactForm">
                    <Label text="name" required="true" />
                    <input type="text" value={name} onChange={this.handleCHange('name')} />
                    <Label text="zip" required="true" />
                    <input type="text" value={zip} onChange={this.handleCHange('zip')} />
                    <Label text="email" required="true" />
                    <input type="text" value={email} onChange={this.handleCHange('email')} />
                    <Label text="company" required="false" />
                    <input type="text" value={company} onChange={this.handleCHange('company')} />
                    <Label text="message" required="true" />
                    <textarea type="text" id="messageText" value={message} onChange={this.handleCHange('message')} />
                    <RequiredInfo />
                    <p id='submitMessage'>{error}<span>{success}</span></p>
                    <button type="submit" form="contactForm" value="Submit" ><SendInfo /></button>
                </form>
            </div>
        </div>
      </div>
    );
  }
  }
  export default Contact;

  function MessageForm(props) {
    const { t } = useTranslation()
    return t(props.message);
  }

  function TitleText() {
    const { t } = useTranslation()
    return(
      <h1>{t('contact')}</h1>
    );
  }

  function Label(props) {
    var required = props.required;
    const { t } = useTranslation()
      return(
        <label>{t(props.text)} {required == 'true'? "*": null }</label>
      );
  }

  function SendInfo() {
    const { t } = useTranslation()
      return(
        <p>{t('send')}</p>
      );
  }

  function RequiredInfo() {
    const { t } = useTranslation()
      return(
        <label>* {t('required')}</label>
      );
  }
  