import "./extraInfo.css";
import React from 'react'
import { useTranslation } from "react-i18next";

function ExtraInfo() {
  const { t } = useTranslation()
    return (
      <div id="extraInfo">
        <div className="container extra-container">
            <p>
            {t('extra_info_1')}
            </p>
            <p>
            <br></br>
            {t('extra_info_2')}
            </p>
        </div>
      </div>
    );
  }
  export default ExtraInfo;
  