import "./footer.css";
import React from 'react'
import { useTranslation } from "react-i18next";

function Footer() {
  const { t } = useTranslation()
  return (
    <div id="footer" className="container footer-container">
            <div className="screenshot">
            </div>
            <div className="footer-section">
                <p>BW Industrial Construction • Admin? <a href="https://bsw-epc.com/admin/"> Login Here</a></p>
            </div>
    </div>
  );
}

export default Footer;
