import "./serve.css";
import React from 'react'
import { useTranslation } from "react-i18next";

function Serve() {
  const { t } = useTranslation()
  return (
    <div id="serve" className="container serve-container">
            <div className="screenshot">
            </div>
            <div className="serve-section">
                <h2>{t('what_we_serve')}</h2>
                <p>{t('serve_description')}</p>
            </div>
    </div>
  );
}

export default Serve;
