import "./about.css";
import React from 'react';
import { useTranslation } from "react-i18next";

function About() {
  const { t } = useTranslation()
    return (
      <div id="about">
        <div className="container about-container">
            <h2>{t('what_is_bw')}</h2>
            <p>
            {t('about_description')}
            </p>
        </div>
      </div>
    );
  }
  export default About;
  