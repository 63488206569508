import './home.css';
import React from 'react'
import { useTranslation } from "react-i18next";

function Home() {
  const { t } = useTranslation()
  return (
    <div id='home' className='container home-container'>
      <div id='background-image'></div>
        <h2>
          {t('home_description')}
        </h2>
        <a id='exploreBtn' href='#about'>{t('explore')}</a>
    </div>
  );
}

export default Home;
