import Home from "./components/home/home";
import Navbar from "./components/navbar/navbar";
import About from "./components/about/about";
import Serve from "./components/serve/serve";
import Gallery from "./components/gallery/gallery";
import ExtraInfo from "./components/extraInfo/extraInfo";
import Contact from "./components/contact/contact";
import Footer from "./components/footer/footer";
import React from 'react'
import './App.css';

function App() {
  return (
    <>
      <Navbar />
      <Home />
      <About />
      <Serve />
      <Gallery />
      <ExtraInfo />
      <Contact />
      <Footer />
    </>
  );
}

export default App;
